import React, {useEffect} from 'react';
import {MdClose} from 'react-icons/md';
import styled from 'styled-components';
import {useDispatch, useSelector} from 'react-redux';
import {Tooltip} from 'antd';
import {GoTrashcan} from 'react-icons/go';
import {useFilter} from '../catalog/context/filterContext';
import {useCatalog} from '../catalog/context/catalogContext';
import {clearCatalog} from '../../store/reducers/catalogSlice';
import {MAX_CHOICES} from '../../../config';
import {getTimeString} from '../../utils/date-utils';

const StyledLi = styled.li`
	position: relative;
	z-index: 999;
	button {
		padding: .2rem;
	}
	svg {
		vertical-align: top;
	}
`;

const StyledTrash = styled.div`
		cursor: pointer;
		color: inherit;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		background-color: #F5F5F8;
		font-size: 0.875rem;
		transition: all 0.25s ease;
		border-radius: 0.625rem;
		padding: 0.3125rem;
		z-index: 999;
		width: 46px;
		display: flex;
		justify-content: center;
		&:hover {
		background-color: #717171;
		
		* {
			fill: #fff;
		}
		}
`;

const SearchChoices = () => {
	const dispatch = useDispatch();
	const {
		searches, setSearches,
		choices, setChoices, name, getFilters, clearFilters,
		setFilterHandler, setLength, length, setRanges, ranges,
	} = useFilter();

	const {
		getCurrentPageData,
	} = useCatalog();

	const {variants} = useSelector((state) => state.filters);

	const setChoicesHandler = (search) => {
		setSearches([...searches.filter((s) => s.title !== search.title)]);
		if (choices[search.type]) {
			setChoices({
				...choices,
				[search.type]: [...choices[search.type].filter((choice) => choice !== search.title)],
			});
		}
	};

	const deleteAllSearchesHandler = () => {
		clearFilters();
		setSearches([]);
		setFilterHandler(null)();
	};

	const deleteSearchHandler = async (search) => {
		await setChoicesHandler(search);
		if (search.type.startsWith('length')) {
			const type = search.type.split('_')[1];
			if (type) {
				const defaultValue = getTimeString(type === 'min' ? variants.length_min : variants.length_max);
				setLength({...length, [type]: defaultValue});
			}
		}
		if (search.type.startsWith('bpm')) {
			setRanges({...ranges, bpm: [variants.bpm_min, variants.bpm_max]});
		}
		dispatch(clearCatalog());
	};

	useEffect(() => {
		const args = {
			filters: {...getFilters()},
		};
		if (name) {
			args.name = name;
		}
		getCurrentPageData(args);
	}, [getCurrentPageData, getFilters, name]);

	return (
		<div className="d-flex align-items-center mr-5">
			{
				searches.length > MAX_CHOICES
				&& (
					<span
						className="header__search-output-placeholder"
						style={{display: 'block'}}
					>
						...
					</span>
				)
			}
			<ul
				className="flex-nowrap align-items-center header__search-output-badges js-search-output--list"
			>

				{
					searches.slice(-MAX_CHOICES).map((search) => {
						return (
							<StyledLi
								className="header__search-output-badge"
							>
								<output className="header__search-output-badge-name text--small">{search.title}</output>
								<button
									onClick={() => deleteSearchHandler(search)}
									type="button"
									className="header__search-output-delete"
								>
									<MdClose/>
								</button>
							</StyledLi>
						);
					})
				}

				{
					!!searches.length && (
						<StyledLi>
							<Tooltip title="Очистить фильтр" placement="top">
								<StyledTrash
									onClick={deleteAllSearchesHandler}
								>
									<GoTrashcan size={20}/>
								</StyledTrash>
							</Tooltip>
						</StyledLi>
					)
				}
			</ul>
		</div>

	);
};

export default SearchChoices;
